jQuery(document).ready(function($) {
    $(document).on('mouseover', '[data-clipboard-action="copy"]:not(.has-clipboard-copy)', function(e) {
        var $this = $(this);
        $this.addClass('has-clipboard-copy');

        $this.tooltip({
            title: 'Click to copy to clipboard',
            placement: 'bottom',
        });

        $this.tooltip('show');

        var clipboard = new ClipboardJS(this);

        clipboard.on('success', function(e) {
            $this.attr('data-original-title', 'Copied to clipboard').tooltip('show');

            setTimeout(function () {
                $this.attr('data-original-title', 'Click to copy to clipboard').tooltip('hide');
            }, 500);

            e.clearSelection();
        });
    });

    // Tabs with url
    $(document).on('show.bs.tab', '[data-toggle="tab"][data-url]:not(.initialized)', function (e) {
        var $this = $(e.target).addClass('initialized');
        var $container = $($this.data('target'));

        $container.load($this.data('url'));
    });

    $(document).on('mouseover', '[data-toggle="tooltip"]:not(.has-tooltip), [data-provide="tooltip"]:not(.has-tooltip)', function(e) {
        $(this).tooltip()
            .tooltip('show')
            .addClass('has-tooltip');
    });
});

function whenAvailable(name, available, notAvailable) {
    var interval = 500; // ms

    if (window[name]) {
        available(window[name]);
    } else {
        if (typeof notAvailable == 'function') {
            notAvailable(name);
        }

        setTimeout(function() {
            whenAvailable(name, available, notAvailable);
        }, interval);
    }
}
